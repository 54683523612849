import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import 'firebase/storage';



const config = {
    apiKey: "AIzaSyBvz2Znt2nmlshdkPUUDNbCHGV4ij_L0Jg",
    authDomain: "prose-pen.firebaseapp.com",
    projectId: "prose-pen",
    storageBucket: "prose-pen.appspot.com",
    messagingSenderId: "941242267185",
    appId: "1:941242267185:web:2630ba5c7dd77dceb20710",
    measurementId: "G-Z8J6K828GX",
  }


const firebase = Firebase.initializeApp(config);
  
const  {FieldValue}  = Firebase.firestore;


const auth = Firebase.auth()

const storage= Firebase.storage()





export { firebase, FieldValue, auth, storage };